import React, { useState, useEffect } from 'react';
import { navigate } from "gatsby";

const RegisterForm = ({ children, loginType, setShowPopup }) => {
  
  const [agreed, setAgreed] = useState(false)

  // This runs when the page is loaded.
  
  useEffect(() => {
    if (localStorage.getItem('18')) {
      setAgreed(true)
    }
  }, [])

  const handleClick = () => {
    localStorage.setItem('18', 'true')
    setAgreed(true)
  }
  
  /*
   const handleClick = () => {
    let toggle = true;
  }
  */
  
  return (

    <div className="member-form">
    	
        <p>Op deze site kan je naast andere producten ook alcoholhoudende dranken kopen.</p>
		<center><button
          onClick={() => {
            handleClick();
            window.location.reload();
          }}
        >
          Klik hier als je 18 jaar of ouder bent.
        </button><br></br><br></br>
        <button
          onClick={() => {
            navigate('https://www.druglijn.be/alcohol-begin-niet-te-vroeg');
          }}
        >
          Ik ben geen 18.
        </button>
        </center>
    </div>
)

}

export default RegisterForm;