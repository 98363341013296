import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { useState, useEffect } from "react"
import Img from 'gatsby-image'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import PropTypes from "prop-types"
import Amplify from 'aws-amplify'
import awsmobile from '../aws-exports'
import Popup from '../components/popup';
import RegisterForm from '../components/registerForm';
import LoginButtons from '../components/loginButtons';

Amplify.configure(awsmobile)

const IndexPage = ({ location, title, children, data }) => {
	
	let toggle;
	
	
	if (typeof window !== 'undefined') {
    if (localStorage.getItem('18')) {
      toggle = false;
    }
    else	
    {
      toggle = true;
    }
	}
	else
	 {
      toggle = true;
    }
	
	 // https://hackernoon.com/how-to-use-a-gatsby-static-site-to-manage-paid-subscriptions
	 
	 const rootPath = `${__PATH_PREFIX__}/`;
	 console.log(rootPath);
	 console.log(location.pathname);
	 const isRootPath = location.pathname === rootPath

	 const[showRegisterPopup, setShowRegisterPopup] = useState(toggle);
	 
	 const [showModal, setShowModal] = useState(false);
		
	 function Modal({ showModal }) {
	  if(!showModal) {
	    return null
	  }
	  return (
	     <Popup showPopup={showRegisterPopup} setShowPopup={setShowRegisterPopup} >
	        <h1 style={{textAlign: 'center', paddingBottom: '2rem'}}>Om verder te gaan.</h1>
	        <RegisterForm loginType='register' setShowPopup={setShowRegisterPopup} />
	      </Popup>
	  )
	}
 
      useEffect(() => {
        const timeId = setTimeout(() => setShowModal(true), 1000)
        return () => clearTimeout(timeId)
      })	
	
return(	
	  <div className="global-wrapper" data-is-root-path={isRootPath}>

  <Layout>
    <SEO title="Home" />
            <React.Fragment>
            <div id="fh5co-services" class="fh5co-bg-section">
				<div class="container">
					<div class="row">
						<div class="text-center">
							<span><h3>{data.site.siteMetadata.title}</h3></span>
							 <div style={{ maxWidth: `200px`, margin: `0 auto`, width: `100%` }}>   
							 </div>  
							<div class="feature-center animate-box fadeIn animated-fast" data-animate-effect="fadeIn">
							</div>
						</div>
					</div>
				</div>
			</div>
            
            <Modal showModal={showModal} />
            
             <div id="fh5co-product">
		<div class="container">
			<div class="row animate-box fadeInUp animated-fast">
				<div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
					<span>Uw spaarinitiatieven veréénvoudigd.</span>
					<h2>Wie zijn we.</h2>
					<p>Steunverkoop is een onderneming die uw organisate ondersteunt in het behalen van de doelen van uw spaardoelen en inzamelacties.</p>
				</div>
			</div>
			<div class="row">
				
			</div>
			
		</div>
		</div>
		

		
            
            
            <div id="fh5co-services" class="fh5co-bg-section">
		<div class="container">
			<div class="row">
				<div class="col-md-4 col-sm-4 text-center">
					<div class="feature-center animate-box fadeIn animated-fast" data-animate-effect="fadeIn">
						<span class="icon">
							<i class="icon-credit-card"></i>
						</span>
						<h3>Een eigen webshop</h3>
						<p>Steunverkoop biedt u kosteloos een eigen webshop aan en ondersteunt online betalingen.</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 text-center">
					<div class="feature-center animate-box fadeIn animated-fast" data-animate-effect="fadeIn">
						<span class="icon">
							<i class="icon-wallet"></i>
						</span>
						<h3>Bereik je spaardoelen.</h3>
						<p>Door iuw spaardoelen kenbaar te maken motiveert sympathisanten om een bijdrage te leveren.</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 text-center">
					<div class="feature-center animate-box fadeIn animated-fast" data-animate-effect="fadeIn">
						<span class="icon">
							<i class="icon-paper-plane"></i>
						</span>
						<h3>Administratieve vereenvoudiging</h3>
						<p>Onze software maakt uw administratie éénvoudiger. Minder last. Minder zorgen voor uw organisatie en beheerders.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	
	           <div id="fh5co-product">
		<div class="container">
			<div class="row animate-box fadeInUp animated-fast">
				<div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
					<span>Producten.</span>
					<h2>Wijnen en Olijfolie.</h2>
					<p>Momenteel hebben we een uitgebreid gamma aan wijnen. Naast wijn bieden we ook Olijfolie aan.</p>
				</div>
			</div>
			<div class="row">
				
			</div>
			
		</div>
		</div>
	
	
	
	
			<div id="fh5co-testimonial" class="fh5co-bg-section">
		<div class="container">
		<div class="row animate-box fadeInUp animated-fast">
			<div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
			
			<h2>Lopende Initatieven</h2>
			</div>
			
			<div class="row">
			<div class="col-md-10 col-md-offset-1">
			<div class="row animate-box fadeInUp animated-fast">
			</div>
			</div>
			</div>
		</div>
		</div>
		</div>
	  
             
			<div id="fh5co-product">
				<div class="container">
					<div class="row animate-box fadeInUp animated-fast">
						<div class="col-md-8 col-md-offset-2 text-center fh5co-heading">

							<h2>Dranken</h2>
							<p>
							</p>
						</div>
					</div>								
					<div class="row">
						{data.Products.edges.map(({ node }, index) => (							
						<div class="col-md-2 text-center animate-box fadeInUp animated-fast">
						<div class="product">					
						<BackgroundImage Tag="header" className="product-grid" fluid={node.relationships.field_image_carrousel.[0].localFile.childImageSharp.fluid} critical >
						<div class="inner">
								<p>
									<button
									id={node.id}
									class="icon Product-button snipcart-add-item"
									data-item-id={node.id}
									data-item-price={node.field_price}
									data-item-image={node.relationships.field_image_carrousel.[0].localFile.childImageSharp.fluid.src}
									data-item-name={node.title}
									data-item-url={`/`}
									data-item-custom1-name="CustomerProfitPercentage"
									data-item-custom1-type="hidden"
									data-item-custom1-value={node.field_custprofitpct}
									>
									<i class="icon-shopping-cart"></i></button>
									
								</p>
							</div>
						</BackgroundImage>
						<div class="desc">
							<h3><Link to={`${node.path.alias}`}>{node.title}</Link></h3>
							<span class="price">{node.field_price} Euro</span>	
							<div dangerouslySetInnerHTML={{ __html: node.body.summary.split(' ').splice(0, 50).join(' ') + '...' }}></div>
							<p>
							<Link to={`${node.path.alias}`}><a href={`${node.path.alias}`} class="icon"> Meer Info </a></Link>
							</p>
						</div>
						<p></p>
						<p>		
									<a
									href="/"
									class="icon Product-button snipcart-add-item"
									data-item-id={node.id}
									data-item-price={node.field_price}
									data-item-image={node.relationships.field_image_carrousel.[0].localFile.childImageSharp.fluid.src}
									data-item-name={node.title}
									data-item-url={`/`}
									data-item-custom1-name="CustomerProfitPercentage"
									data-item-custom1-type="hidden"
									data-item-custom1-value={node.field_custprofitpct}
									>
									<i class="icon-shopping-cart"></i>  Add to Cart</a>
									<p>
										
									</p>
						</p>

					
						</div>
						</div>
					))}
					</div>
					
					
						</div>
			</div>
					
					<div id="fh5co-started">
					<div class="container">
					<div class="row animate-box fadeInUp animated-fast">
					<div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
					<h2>Spaardoel</h2>
					<p>Help ons het volgende doel te realiseren.</p>
					</div>
					</div>
					<div class="row animate-box fadeInUp animated-fast">
					<div class="col-md-8 col-md-offset-2">
					<div class="row">
						{data.Events.edges.map(({ node }, index) => (
						<div class="col-md-12 text-center animate-box fadeInUp animated-fast">
						<div class="product">	
								<div class="desc">
									<h3><Link to={`${node.path.alias}`}>{node.title}</Link></h3>
									<Link to={`${node.path.alias}`}>
										<BackgroundImage Tag="header" className="product-grid" fluid={node.relationships.field_event_image.localFile.childImageSharp.fluid}>
										<div class="inner">
										<p>
										</p>
										</div>
										</BackgroundImage>
									</Link>
									</div>			
						</div>	
						</div>
					))}
					</div>
					
		</div>
	</div>						
				</div>				
	        </div>
	            
		  	</React.Fragment>	  	
  </Layout>
  </div>
  )
}

export default IndexPage

export const query = graphql`
query productsQuery {
 file(name: { eq: "logo" }, extension: { eq: "png" }) {
        childImageSharp {
	      gatsbyImageData(width: 100)  
        }
      }	
site {
    siteMetadata {
      title
    }
  }
  Products:allNodeWebshopProduct(filter: {relationships: {group_content__group_content_type_eaf1e027bc183: {elemMatch: {drupal_id: {}, path: {langcode: {}}, relationships: {gid: {drupal_internal__id: {in:[7,8,5] }}}}}}}) {
    edges {
      node {
        title
        id
        created(formatString: "DD MMMM YYYY")
        field_price
        field_custprofitpct
        body {
          summary
        }
        path {
          alias
        }
        relationships {
          field_image_carrousel {
            localFile {
              childImageSharp {
	            gatsbyImageData(width: 800)
                fluid(maxWidth: 800, quality: 100) {
	              ...GatsbyImageSharpFluid
                  src
                }
              }
            }
          }
        }
      }
    }
  }
  Events:allNodeEvent(filter: {relationships: {group_content__closed_group_group_node_event: {elemMatch: {relationships: {gid: {drupal_internal__id: {in: [7]}}}}}}}) {
    edges {
      node {
        title
        id
        path {
          alias
        }
        relationships {
          field_event_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
	              ...GatsbyImageSharpFluid  
                  src
                }
              }
            }
          }
        }
      }
    }
  }
}
`